var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.open
      ? _c(
          "div",
          {
            staticClass:
              "fixed z-100 max-h-full w-full h-screen bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 flex items-end sm:items-center justify-center",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full sm:max-w-lg sm:w-full z-20",
                attrs: { "data-testid": "delete-modal" },
              },
              [
                _vm._t("body", function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4" },
                      [
                        _c("div", { staticClass: "sm:flex sm:items-start" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10",
                            },
                            [
                              _c("exclamation-icon", {
                                staticClass: "h-6 w-6 text-red-600",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left",
                            },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "text-lg leading-6 font-bold text-gray-900",
                                },
                                [
                                  _vm._t("title", function () {
                                    return [
                                      _vm._v(
                                        " " + _vm._s(_vm.defaultTitle) + " "
                                      ),
                                    ]
                                  }),
                                ],
                                2
                              ),
                              _c("div", { staticClass: "mt-2" }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm leading-5 text-gray-600",
                                  },
                                  [
                                    _vm._t("description", function () {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.defaultDescription) +
                                            " "
                                        ),
                                      ]
                                    }),
                                  ],
                                  2
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                }),
                _vm._t("footer", function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto",
                          },
                          [
                            _c(
                              "sun-button",
                              {
                                staticClass: "custom-p-1 text-xs w-full",
                                class: _vm.confirmButtonClass,
                                attrs: {
                                  variant: "pill",
                                  color: _vm.confirmButtonColor,
                                  disabled: _vm.loading || _vm.buttonDisabled,
                                  loading: _vm.loading || _vm.buttonDisabled,
                                },
                                on: { click: _vm.emitConfirm },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.confirmButtonText) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto",
                          },
                          [
                            _c(
                              "sun-button",
                              {
                                staticClass: "custom-p-1 text-xs w-full",
                                class: _vm.cancelButtonClass,
                                attrs: {
                                  variant: "pill",
                                  color: _vm.cancelButtonColor,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("cancel")
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(_vm.cancelButtonText) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm.closeOnBlur
              ? _c("div", {
                  staticClass: "absolute inset-0 z-10 bg-gray-800 opacity-50",
                  attrs: { tabindex: "-1" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                })
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }