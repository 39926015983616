<template>
  <transition name="fade">
    <div
      v-if="open"
      class="fixed z-100 max-h-full w-full h-screen bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 flex items-end sm:items-center justify-center"
    >
      <div
        data-testid="delete-modal"
        class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full sm:max-w-lg sm:w-full z-20"
      >
        <slot name="body">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <exclamation-icon class="h-6 w-6 text-red-600" />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-bold text-gray-900">
                  <slot name="title">
                    {{ defaultTitle }}
                  </slot>
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-600">
                    <slot name="description">
                      {{ defaultDescription }}
                    </slot>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </slot>
        <slot name="footer">
          <div class="bg-gray-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row">
            <div class="flex w-full rounded-md shadow-sm sm:mr-3 sm:w-auto">
              <sun-button
                variant="pill"
                class="custom-p-1 text-xs w-full"
                :color="confirmButtonColor"
                :class="confirmButtonClass"
                :disabled="loading || buttonDisabled"
                :loading="loading || buttonDisabled"
                @click="emitConfirm"
              >
                {{ confirmButtonText }}
              </sun-button>
            </div>
            <div class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
              <sun-button
                variant="pill"
                class="custom-p-1 text-xs w-full"
                :color="cancelButtonColor"
                :class="cancelButtonClass"
                @click="$emit('cancel')"
              >
                {{ cancelButtonText }}
              </sun-button>
            </div>
          </div>
        </slot>
      </div>

      <div
        v-if="closeOnBlur"
        class="absolute inset-0 z-10 bg-gray-800 opacity-50"
        tabindex="-1"
        @click="$emit('cancel')"
      />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DeleteModal',
  components: {
    ExclamationIcon: () => import('@/components/icons/ExclamationSvg'),
  },
  props: {
    open: {
      type: Boolean,
      default: () => false,
    },
    confirmButtonColor: {
      type: String,
      default: () => 'red',
    },
    confirmButtonText: {
      type: String,
      default: () => 'Delete',
    },
    confirmButtonClass: {
      type: String,
      default: () => '',
    },
    cancelButtonColor: {
      type: String,
      default: () => 'white',
    },
    cancelButtonText: {
      type: String,
      default: () => 'Cancel',
    },
    cancelButtonClass: {
      type: String,
      default: () => 'text-gray-700 hover:bg-gray-100 hover:text-gray-500',
    },
    closeOnBlur: {
      type: Boolean,
      default: () => true,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      defaultTitle: 'Are you sure?',
      defaultDescription: 'an element is going to be deleted.',
      buttonDisabled: false,
    };
  },
  watch: {
    open(newValue) {
      if (!newValue) {
        this.buttonDisabled = false;
      }
    },
  },
  methods: {
    emitConfirm() {
      this.buttonDisabled = true;
      this.$emit('confirm');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
